import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Home from 'endpoints/Home/Home';
import Metatags from '../components/Metatags/Metatags';
import fetcher from '../utils/fetcher';
import CQUERY from 'endpoints/Collection/col.gql';
import RADARQUERY from 'endpoints/Home/radar.gql';
import ELECTIONQUERY from 'endpoints/Home/electionresults.gql';
import { getServerSession } from 'next-auth';
import { authOptions } from 'pages/api/auth/[...nextauth]';
import { linkByTypeAs } from 'utils/cjsutils';
import AdCategoriesContext from 'context/AdCategoriesContext';
import Head from 'next/head';

const HomePage = ({ homepage, radar, electionresults }) => {
  const context = useContext(AdCategoriesContext);
  const homePageListItems = homepage.collection.results.items;
  /* eslint-disable no-dupe-keys */
  const homePageSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: `https://www.mprnews.org`,
    name: 'MPR News',
    alternateName: 'Minnesota Public Radio News',
    alternateName: 'MPRnews',
    alternateName: 'mprnews',
    image: {
      '@type': 'ImageObject',
      url: 'https://www.mprnews.org/mpr-news-800x418.png',
      height: '800',
      width: '418',
      creditText: 'MPR News',
    },
    image: {
      '@type': 'ImageObject',
      url: 'https://www.mprnews.org/mpr-news-940x788.png',
      height: '940',
      width: '788',
      creditText: 'MPR News',
    },
    image: {
      '@type': 'ImageObject',
      url: 'https://www.mprnews.org/mpr-news-1080x1080.png',
      height: '1080',
      width: '1080',
      creditText: 'MPR News',
    },
    mainEntity: {
      '@type': 'ItemList',
      numberOfItems: homePageListItems.length,
      itemListElement: homePageListItems.map((item, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        url: `https://www.mprnews.org/${linkByTypeAs(item)}`,
      })),
    },
    publisher: {
      '@type': 'NewsMediaOrganization',
      name: 'MPR News',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.mprnews.org/mpr-news-1080x1080.png',
        height: '1080',
        width: '1080',
      },
      foundingDate: '1967-01-22',
      ethicsPolicy: 'https://www.mprnews.org/ethics',
      sameAs: [
        'https://www.facebook.com/MPRnews/',
        'https://x.com/MPRnews',
        'https://www.instagram.com/mprnews',
        'https://www.youtube.com/user/mprdotorg',
        'https://www.linkedin.com/company/mpr-news/',
        'https://www.wikidata.org/wiki/Q6868408',
        'https://en.wikipedia.org/wiki/Minnesota_Public_Radio',
      ],
    },
  };

  useEffect(() => {
    context.setPrimaryCategory('homepage');
  });

  /* eslint-disable no-dupe-keys */
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(homePageSchema) }}
        />
      </Head>
      <Metatags fullSlug="" topic="homepage" />
      <Home
        collection={homepage.collection}
        radar={radar.radarConfig}
        alert={homepage.alertConfig}
        electionresults={electionresults}
      />
    </>
  );
};

export async function getServerSideProps(context) {
  const [homepage, radar, electionresults, session] = await Promise.all([
    fetcher(CQUERY, {
      contentAreaSlug: process.env.CONTENT_AREA_SLUG,
      slug: 'homepage',
      pageSize: 20,
    }),
    fetcher(RADARQUERY),
    fetcher(ELECTIONQUERY),
    getServerSession(context.req, context.res, authOptions),
  ]);

  return {
    props: {
      resourceType: 'homepage',
      homepage,
      radar,
      electionresults: electionresults.electionresults,
      session,
      data: {},
    },
  };
}

HomePage.propTypes = {
  homepage: PropTypes.object,
  radar: PropTypes.object,
  electionresults: PropTypes.object,
};

export default HomePage;
