import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import HomeOnAir from './components/onAir';
import MnTodayPromo from 'endpoints/Home/components/mnTodayPromo';
import LatestNews from 'endpoints/Home/components/latestNews';
import Podcast from './components/podcast';
import FancySlider from './components/fancySlider';
import SpecialCoverage from 'endpoints/Home/components/specialCoverage';
import FeaturedStories from 'endpoints/Home/components/featuredStories';
import Boxes from './components/boxes';
import ShareIdeasBanner from './components/shareIdeasBanner';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';
import FeaturedEvents from './components/events';
import AlertWrapper from 'components/Alert/AlertWrapper';
import Greeting from './components/Greeting';
import { DefaultCollection } from 'models/Collection/CollectionType';
import MoreNews from './components/moreNews';
import WeatherSideBar from './components/weatherSideBar';
import WeatherGlance from './components/weatherGlance';
import { WeatherProvider } from 'context/WeatherContext';
import { dateStringAbbrevMonth } from 'utils/dateUtils';

export interface HomeProps {
  collection?: DefaultCollection;
  radar: {
    slug: string;
    json: string;
  };
  electionresults?: {
    slug: string;
    json: string;
  };
}
const Home = ({ collection, radar, electionresults }: HomeProps) => {
  const [dte, setDte] = useState('');
  const resultsinfo = electionresults ? JSON.parse(electionresults.json) : null;

  useEffect(() => {
    const date = dateStringAbbrevMonth();
    setDte(date);
  }, []);

  useEffect(() => {
    if (resultsinfo?.results_on == false) return;
    const script = document.createElement('script');
    script.src =
      'https://interactives.ap.org/election-results/assets/microsite/resizeClient.js';
    document.head.prepend(script);
  }, []);

  return (
    <div data-mpr-content-topic="homepage">
      <AlertWrapper resourceType="home" />
      <Head>
        <link href="/css/pages/home.css" rel="stylesheet" />
      </Head>
      <div className="wrapper">
        <div className="container">
          <div className="home-top home-segment">
            <div className="header-ad">
              <AdvertisementBanner id="mpr-ad-1" />
            </div>
            <div className="home-top-greeting-container">
              <Greeting />
              {dte && <div>{dte}</div>}
              <WeatherProvider>
                <WeatherGlance />
              </WeatherProvider>
            </div>
          </div>
        </div>
        <div className="container grid grid-home-news">
          <div className="home-card home-topnews">
            <FeaturedStories radar={radar} collection={collection} />
            <>
              {resultsinfo?.results_on && (
                <>
                  {resultsinfo.widgets.map((widget: { widget: string }) => {
                    switch (widget.widget) {
                      case 'ussenate':
                        return (
                          <div className="election-embeds" key={widget.widget}>
                            <iframe
                              className="ap-embed"
                              title="Live election results via the Associated Press"
                              src="https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/39803/14222.html"
                              width="100%"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight={0}
                            ></iframe>
                            <iframe
                              className="ap-embed"
                              title="Live election results via the Associated Press"
                              src="https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/39803/14221.html"
                              width="100%"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight={0}
                            ></iframe>
                          </div>
                        );
                      case 'cong_dist/2':
                        return (
                          <iframe
                            className="ap-embed"
                            title="Live election results via the Associated Press"
                            src="https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/39803/14285.html"
                            width="100%"
                            frameBorder="0"
                            scrolling="no"
                            marginHeight={0}
                          ></iframe>
                        );
                      case 'cong_dist/5':
                        return (
                          <div className="election-embeds" key={widget.widget}>
                            <iframe
                              className="ap-embed"
                              title="Live election results via the Associated Press"
                              src="https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/39803/14281.html"
                              width="100%"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight={0}
                            ></iframe>
                          </div>
                        );
                      case 'cong_dist/7':
                        return (
                          <div className="election-embeds" key={widget.widget}>
                            <iframe
                              className="ap-embed"
                              title="Live election results via the Associated Press"
                              src="https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/39803/14283.html"
                              width="100%"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight={0}
                            ></iframe>
                          </div>
                        );
                      case 'mnsenate_45':
                        return (
                          <div className="election-embeds" key={widget.widget}>
                            <iframe
                              className="ap-embed"
                              title="Live election results via the Associated Press"
                              src="https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/39803/14286.html"
                              width="100%"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight={0}
                            ></iframe>
                          </div>
                        );
                      case 'all_races':
                        return (
                          <div className="election-embeds" key={widget.widget}>
                            <iframe
                              className="ap-embed"
                              title="Live election results via the Associated Press"
                              src="https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/39803/14279.html"
                              width="100%"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight={0}
                            ></iframe>
                          </div>
                        );
                    }
                  })}

                  <div>
                    <Link
                      className="btn btn-primary"
                      href="https://www.mprnews.org/2024-primary-election-results"
                      passHref={true}
                    >
                      View More Election Results
                    </Link>
                  </div>
                </>
              )}
            </>

            <LatestNews radar={radar} collection={collection} />
          </div>

          <div className="home-today">
            <WeatherProvider>
              <WeatherSideBar />
            </WeatherProvider>
            <MnTodayPromo radar={radar} />
          </div>
          <div className="home-promobox">
            <Boxes />
          </div>
          <div className="home-morenews">
            <MoreNews radar={radar} collection={collection} />
          </div>
          <div className="home-audio grid grid-home-on-air-podcasts">
            <HomeOnAir />
            <Podcast />
          </div>
        </div>
      </div>
      <section className="home-segment">
        <FancySlider />
      </section>
      <section className="home-what-next home-segment container">
        <ShareIdeasBanner />
      </section>
      <section className="home-special-coverage home-segment container-triangle-bg">
        <SpecialCoverage />
      </section>
      <div className="wrapper">
        <FeaturedEvents />
      </div>
      <div className="header-ad">
        <AdvertisementBanner id="mpr-ad-3" />
      </div>
    </div>
  );
};

export default Home;
