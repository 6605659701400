'use client';
import Link from 'next/link';
import { linkByTypeAs } from 'utils/cjsutils';
import AudioPlayButton from 'components/AudioPlayButton/AudioPlayButton';
import { audioDownloadPrefix } from 'utils/utils';
import Loading from 'components/Loading/Loading';
import { Image } from '@apmg/mimas';
import { CollectionItem } from 'models/Collection/CollectionType';
import { HomeProps } from '../Home';
import CategoryByline from 'components/CategoryByline/CategoryByline';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';

const LatestNews = ({ collection, radar }: HomeProps) => {
  const stories = collection?.results.items;
  const showRadar = JSON.parse(radar.json).general.radar_on;

  // const showRadar = true;
  const startNumber = showRadar ? 0 : 1;
  const endNumber = showRadar ? 6 : 7;

  return (
    <div className="home-latest-news">
      <ul className="home-stories">
        {stories ? (
          stories?.slice(startNumber, endNumber).map((item: CollectionItem) => {
            const audioSquareImage =
              item.primaryVisuals?.thumbnail?.aspect_ratios.square?.instances[0]
                .url;
            return (
              <li key={item.canonicalSlug}>
                <Link
                  href={linkByTypeAs(item)}
                  key={item.canonicalSlug}
                  passHref={true}
                >
                  <h2 className="home-story-hdg type-lg">{item.title}</h2>
                </Link>
                <div
                  className={`home-story ${
                    item?.primaryVisuals?.thumbnail ? 'home-story-has-img' : ''
                  }`}
                >
                  {item.primaryVisuals?.thumbnail?.aspect_ratios?.thumbnail && (
                    <div className="home-latest-news-img">
                      <Link
                        href={linkByTypeAs(item)}
                        key={item.canonicalSlug}
                        passHref={true}
                        aria-hidden /* Prevent duplicate links.
                          If it was not aria-hidden, its alt would be the story title 
                          due to image link alts describing their destination.
                          Therefore, it's redundant. */
                      >
                        <Image
                          image={item.primaryVisuals.thumbnail}
                          aspectRatio="thumbnail"
                          sizes="(min-width: 72em) 10vw, 80vw"
                          alt={item.title}
                        />
                      </Link>
                    </div>
                  )}
                  <div className="home-story-text">
                    <CategoryByline
                      publishDate={item.publishDate}
                      updatedPublishDate={item.updatedPublishDate}
                      primaryCollection={item.primaryCollection}
                    />
                    {item?.descriptionText && <p>{item.descriptionText}</p>}
                    <div className="home-stories-controls">
                      {item.audio[0]?.encodings[0] ? (
                        <AudioPlayButton
                          audioTitle={item.title}
                          label="Listen"
                          audioSource={audioDownloadPrefix(
                            item.audio[0].encodings[0].playFilePath
                          )}
                          audioThumbnailSrc={audioSquareImage}
                          audioThumbnailAlt={
                            audioSquareImage &&
                            item.primaryVisuals?.lead?.shortCaption
                          }
                          showDuration={`${
                            item.audio[0].encodings[0].durationHms &&
                            item.audio[0].encodings[0].durationHms
                          }`}
                          playBtnSm={true}
                        />
                      ) : (
                        <span></span>
                      )}
                      <AddFavoriteWrapper
                        title={item.title}
                        contentArea="mprnews"
                        resourceType={item.resourceType}
                        id={item.id}
                        slug={item.canonicalSlug}
                        showIcon={true}
                        showBackground={false}
                      />
                    </div>
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <Loading />
        )}
      </ul>
      <div className="home-stories-bottom">
        <Link
          className="btn btn-primary btn-slim home-btn-more"
          href={`/content/${new Date()
            .toLocaleDateString('en-US')
            .replace(/\//g, '-')}`}
        >
          More top stories from MPR News →
        </Link>
      </div>
    </div>
  );
};

export default LatestNews;
