'use client';
import React, { FC, useState, useEffect, useRef } from 'react';
import fetcher from 'utils/fetcher';
import { CollectionItem } from 'models/Collection/CollectionType';
import { ConditionCode, ConditionCodesArray } from 'utils/conditionCodes';
import dayjs from 'dayjs';
import { CtoF } from 'utils/utils';
import { gql } from 'graphql-request';
import { Loading } from '@apmg/titan';

export interface WeatherContextType {
  updraft?: CollectionItem[] | undefined;
  weatherData?: WeatherData;
}

interface WeatherData {
  currentWeather: {
    temperature: string | number | undefined;
    conditionCode: string;
    weatherCodeIconPath: string | undefined;
    weatherImageAlt: ConditionCode[];
  };
}

type Props = {
  children: React.ReactNode;
};

const initialWeatherState: WeatherContextType = {
  updraft: [],
};

export const WeatherContext =
  React.createContext<WeatherContextType>(initialWeatherState);

export const WeatherProvider: FC<Props> = ({ children }) => {
  const [updraft, setUpdraft] = useState<CollectionItem[] | undefined>();
  const [weather, setWeather] = useState<WeatherData | null>();
  const time = dayjs(new Date()).tz('America/Chicago');
  const hours = dayjs(time).hour();
  const isDayTime = hours > 6 && hours < 20;
  let weatherDataFetched = false;
  let updraftDataFetched = false;

  const UPDRAFTQUERY = gql`
    query Collection($slug: String!, $contentAreaSlug: String!) {
      collection(contentAreaSlug: $contentAreaSlug, slug: $slug) {
        id
        results(page: 1, pageSize: 1) {
          items {
            id
            title
            resourceType
            canonicalSlug
          }
        }
      }
    }
  `;

  const conditionCodesWithDayOrNightIcon = [
    'partlycloudy',
    'clear',
    'mostlyclear',
    'sunshowers',
    'sleet',
    'rain',
    'thunderstorms',
    'snowshowers',
    'hot',
    'heaveyrain',
    'snow',
    'heavysnow',
  ];

  const getWeatherKitData = async () => {
    const method = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const currentWeatherRequest = await fetch(
      `api/weather/44.986656/-93.258133`,
      method
    );

    const currentWeatherResponse = await currentWeatherRequest.json();
    weatherDataFetched = true;
    setWeather(currentWeatherResponse);
  };

  const getUpdraftData = async () => {
    const data = await fetcher(UPDRAFTQUERY, {
      contentAreaSlug: process.env.CONTENT_AREA_SLUG,
      slug: 'weather-and-climate/updraft',
    });
    updraftDataFetched = true;
    setUpdraft(data?.collection.results.items);
  };

  useEffect(() => {
    getWeatherKitData();
    getUpdraftData();
  }, []);

  if (!weather) {
    return <Loading />;
  }

  const WeatherSVGwithDayOrNight = (svgName: string) => {
    let results;
    if (conditionCodesWithDayOrNightIcon.includes(svgName)) {
      if (isDayTime) {
        results = `${svgName}_day`;
      } else if (isDayTime == false) {
        results = `${svgName}_night`;
      }
      return results;
    }
    return svgName;
  };

  const currentTemp =
    weather?.currentWeather?.temperature &&
    CtoF(weather?.currentWeather.temperature);

  const weatherCodeIconPath =
    weather.currentWeather.conditionCode &&
    WeatherSVGwithDayOrNight(
      weather.currentWeather.conditionCode.toString().toLowerCase()
    );

  const weatherCode =
    weather?.currentWeather?.conditionCode &&
    weather.currentWeather.conditionCode;

  const weatherImageAlt = ConditionCodesArray.filter((weather, i) => {
    let results;
    if (weather.code === weatherCode) {
      results = ConditionCodesArray[i].description;
    }
    return results;
  });

  const weatherData = {
    currentWeather: {
      temperature: currentTemp,
      conditionCode: weather.currentWeather.conditionCode,
      weatherCodeIconPath: weatherCodeIconPath,
      weatherImageAlt: weatherImageAlt,
    },
  };

  return (
    <WeatherContext.Provider
      value={{
        updraft,
        weatherData,
      }}
    >
      {children}
    </WeatherContext.Provider>
  );
};

export default WeatherContext;
