import React, { FC } from 'react';
import { CollectionItem } from 'models/Collection/CollectionType';
import Card from 'components/Slider/card';
import IconTriangle from 'components/Icons/IconTriangle';
import Link from 'next/link';
import { linkByTypeAs } from 'utils/cjsutils';
import { getVideoPlayer } from 'utils/utils';

const ScrollMenuCard: FC<CollectionItem> = (item) => {
  const VideoPlayer =
    item.primaryVisuals.video &&
    getVideoPlayer(item.primaryVisuals.video, false, item.title);
  return (
    <Card
      key={item.canonicalSlug}
      itemId={item.canonicalSlug}
      className="type-lg fancy-slider-card"
    >
      {item.primaryVisuals.video && (
        <figure className="figure">{VideoPlayer}</figure>
      )}

      {!VideoPlayer && item.primaryVisuals?.lead?.aspect_ratios?.normal && (
        <Link
          href={linkByTypeAs(item)}
          passHref={true}
          aria-hidden /* prevent duplicate links */
        >
          <div className="fancy-slider-img-outer">
            <img
              src={
                item.primaryVisuals?.lead?.aspect_ratios?.normal.instances[2]
                  .url
              }
              alt={item.title}
              className="fancy-slider-img"
            />
          </div>
        </Link>
      )}
      <div className="fancy-slider-text">
        {item.primaryCollection && (
          <Link
            href={item.primaryCollection?.canonicalSlug}
            className="category-link category-link-dark-bg"
            passHref
          >
            <IconTriangle />
            {item.primaryCollection?.title}
          </Link>
        )}

        <Link href={linkByTypeAs(item)} passHref={true}>
          <div className="fancy-slider-title">{item.title}</div>
        </Link>
        <div className="type-sm">
          by{' '}
          {item.contributors.map((contributor, i) => {
            const contributorName = `${contributor.profile.firstName} ${contributor.profile.lastName}`;
            if (
              item.contributors.length > 1 &&
              i < item.contributors.length - 1
            ) {
              return contributorName + ', ';
            }
            return contributorName;
          })}
        </div>
      </div>
    </Card>
  );
};

export default ScrollMenuCard;
